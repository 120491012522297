import { useEffect } from 'react';
import propTypes from 'prop-types';

const regex = /<script.{0,}>((.|\n)*?)(?=<\/script>)/g;

export function TildaInit({ tildaInitScripts }) {
  useEffect(() => {
    const jsFromScripts = tildaInitScripts.map((tildaScript) =>
      Array.from(tildaScript.matchAll(regex), (x) => x[1]).join(';\n')
    );

    const html = `
      if (document.readyState === 'loading') {
        window.addEventListener('DOMContentLoaded', () => {
          console.log('READY');
          console.log('INIT');
          ${jsFromScripts.join(';\n')}
          console.log('DONE');
        });
      } else {  
        console.log('INIT');
        ${jsFromScripts.join(';\n')}
        console.log('DONE');
      }
    `;

    const tag = document.createElement('script');
    tag.async = false;
    tag.defer = true;

    try {
      tag.appendChild(document.createTextNode(html));
    } catch (e) {
      tag.text = html;
    }

    const parent = document.getElementById('tilda-scripts');
    parent.appendChild(tag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

TildaInit.propTypes = {
  tildaInitScripts: propTypes.arrayOf(propTypes.string),
};

TildaInit.defaultProps = {
  tildaInitScripts: [],
};
